@import "bvdash/styles/variables";
@import "bvdash/styles/mixins";

.photo {
  margin-right: 22px;
  width: 25px;
  height: 25px;
  vertical-align: middle;
  border-radius: 50%;
  display: inline-flex;
}

.filters {
  @extend %card-shadow;
  padding: 12px 50px;
  margin-bottom: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: $solid-white;
  border-radius: 5px;
}

.spacer {
  flex-grow: 1;
}

.addBtn {
  color: $waterloo;
  font-size: 14px;

  text-decoration: none;

  &::before {
    vertical-align: middle;
    content: "+ ";
    color: $dodger-blue;
    font-size: 20px;
    font-weight: 500;
  }
}

.empty {
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  text-align: center;
  background-color: $solid-white;
}

.emptyTitle {
  margin-top: 25px;
  margin-bottom: 36px;
	font-size: 16px;
	font-weight: 500;

  color: $san-juan;
}

.emptyDescription {
  margin-bottom: 26px;
  font-size: 14px;
	line-height: 1.57;

	color: $waterloo;
}
