@import "bvdash/styles/variables";
@import "bvdash/styles/mixins";

.checkbox {
  position: relative;

  display: block;
  width: 19px;
  height: 19px;
  border-radius: 2px;
  border: solid 1px #304d71;
  background: none;
}

// check
.checked::after {
  position: absolute;
  left: 7px;
  top: 3px;
  width: 5px;
  height: 10px;
  border: solid $san-juan;
  border-width: 0 2px 2px 0;

  transform: rotate(45deg);
  content: " ";
}
