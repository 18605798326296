@import "bvdash/styles/variables";

.filters {
  display: flex;
  align-items: center;
  padding: 10px 30px;
	border-radius: 5px 5px 0 0;
  background-color: $solid-white;
  font-size: 12px;
  justify-content: space-between;
  max-width: 100%;
  width: 100%;
} 
