@import 'bvdash/styles/variables';
@import 'bvdash/styles/mixins';

.root {
  margin: 0;
  outline: 0;
  cursor: pointer;
  text-decoration: none; 
}

.size {
  &default {
    height: 44px;
    padding: 15px 18px;
    font-size: 14px;
    line-height: 15px;
  }

  &resize {
    height: 5vh;
    padding: 8px;
    width: 10vh;
    display: flex;
    justify-content: center;
  }

  &small {
    height: 30px;
    font-size: 12px;
  }

  &large {
    height: 59px;
    padding: 22px 26px;
    font-size: 14px;
    line-height: 15px;
  }
}

%theme {
  position: relative;
  display: inline-flex;
  align-items: center;
  padding: 0 20px;

  color: $san-juan;
}

.theme {
  &default {
    @extend %theme;

    background-color: $solid-white;
    border: solid 1px $drop-shadow;
    border-radius: 5px;
    box-shadow: 0 2px 3px 0 rgba(49, 61, 90, .14);

    &.hover {
      color: $dodger-blue;

      svg {
        fill: $dodger-blue !important;
      }
    }
  }

  &primary {
    @extend %theme;

    color: $solid-white;
    background-color: $dodger-blue;
    border: solid 1px $dodger-blue;
    border-radius: 5px;
    box-shadow: 0 2px 3px 0 rgba(49, 61, 90, .14);

    &.hover {
      background-color: darken($dodger-blue, 5%);
    }

    &.disabled {
      border: #8bd0fd;
      background-color: #8bd0fd;
      cursor: not-allowed;
    }
  }

  &danger {
    @extend %theme;

    color: $solid-white;
    background-color: $carnation;
    border: solid 1px $carnation;
    border-radius: 5px;
    box-shadow: 0 2px 3px 0 rgba(49, 61, 90, .14);

    &.hover {
      background-color: darken($carnation, 5%);
    }
  }

  &warning {
    @extend %theme;

    color: $solid-white;
    background-color: $neon-carrot;
    border: solid 1px $neon-carrot;
    border-radius: 5px;
    box-shadow: 0 2px 3px 0 rgba(49, 61, 90, .14);

    &.hover {
      background-color: darken($neon-carrot, 5%);
    }
  }

  &link {
    @extend %theme;
    height: auto;
    padding: 0;

    border: none;
    background: none;

    text-decoration: none;
    text-align: left;
    line-height: 1.4;


    &.hover {
      color: $dodger-blue;

      svg {
        fill: $dodger-blue !important;
      }
    }

    &.disabled {
      cursor: not-allowed;
    }

    &.icon {
      padding: 0;
    }

    &-muted {
      color: $waterloo;
    }

  }
  &linkActive {
    @extend %theme;
    color: $dodger-blue;

    height: auto;
    padding: 0;

    border: none;
    background: none;

    text-decoration: none;
    text-align: left;
    line-height: 1.4;
  }

  &header {
    @extend %theme;
    @extend %text-bold;

    background-color: $solid-white;
    box-shadow: none;
    border: none;
    border-radius: 5px;

    &.hover {
      color: $solid-white;
      background-color: darken($dodger-blue, 5%);

      svg {
        fill: $solid-white !important;
      }
    }
  }
}

.icon {
  margin-right: 14px;
}

.iconLeft > span { margin-left: 10px; }
.iconRight > span { margin-right: 10px; }

.fullWidth {
  width: 100%;
  justify-content: center;
}
