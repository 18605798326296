@import "bvdash/styles/mixins";

$horizontal-padding: 20px;
$active-width: 3px;

.item {
  @extend %selectable;
  white-space: nowrap;
  padding: 10px $horizontal-padding;

  & + & {
    border-top: 1px solid $drop-shadow;
  }

  &:hover {
    @extend .active;
    background-color: $alabaster-light;
  }

  &:first-child {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }

  &:last-child {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }
}

.active {
  @extend %menu-item-hover;
}
