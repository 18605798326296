@import "bvdash/styles/variables";
@import "bvdash/styles/mixins";

.root {
  align-self: center;
  display: inline-block;
  height: 40px;
  padding-left: 16px;
  position: relative;

  @media screen and (max-width:1270px){
    display: none;
  }
}

.trigger {
  @extend %selectable;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.image {
  @include circle(40px);
  margin-right: 14px;
}

.user {
  margin-right: 18px;
  display: flex;
  flex-direction: column;
  line-height: 1.2;
}

.userName {
  @extend %text-bold;
  color: $san-juan;
}

.userRole {
  color: $waterloo;
}

.choice {
  & + & {
    border-top: 1px solid $drop-shadow;
  }

  &:hover {
    svg {
      color: $dodger-blue;
    }
  }

  &:first-child * {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }

  &:last-child * {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  svg {
    margin-right: 12px;
  }
}
