@import "bvdash/styles/mixins";

.root {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.clickable {
  @extend %selectable;
  outline: none;
}

.icon {
  margin-bottom: 26px;
}
