@import "bvdash/styles/variables";
@import "bvdash/styles/mixins";

.header {
  height: 25px;
  background-color: #fcfcfc;
  box-shadow: inset 0 -1px 0 0 #dcdfe2;
}

.cell {
  @extend %selectable;
  display: flex;
  align-items: center;
}

.label {
  margin-right: 10px;
}

.active {
  color: $san-juan;
}
