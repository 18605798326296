@import 'bvdash/styles/variables';

.headline1 {
  font-size: 24px;
  font-weight: 500;

  color: $san-juan;
}

.headline2 {
  margin-top: 19px;
  margin-bottom: 6px;
  font-size: 14px;
  font-weight: 400;

  color: $san-juan;
}

.headline3 {
  font-size: 14px;
  font-weight: 500;
  line-height: 2em;
  color: $san-juan;
  margin: 12px 0;
}

.headline4 {
  font-size: 13px;
  font-weight: 400;

  color: $waterloo;
}

.headline5 {
  font-size: 10px;
  font-weight: 500;

  text-transform: uppercase;
  color: rgba($waterloo, 0.9);
}

.left { text-align: left; }
.center { text-align: center; }
.right { text-align: right; }
