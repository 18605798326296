@import "bvdash/styles/variables";
@import "bvdash/styles/mixins";

.btn {
  @extend %selectable;
  border: none;
  background: none;

  font-size: 13px;

	color: $waterloo;

  &:not(.disabled):hover {
    text-decoration: underline;
    color: $san-juan;
  }
}

.disabled {
  cursor: not-allowed;
}

.primary {
  font-weight: 500;
  color: $dodger-blue;

  &:not(.disabled):hover {
    color: $dodger-blue;
  }
}
