@import 'bvdash/styles/variables';

.body {
  display: flex;
  align-items: center;
  padding: 20px;
}

.text {
  display: flex;
  flex-direction: column;

  //height: 5em*1.57;
  padding-right: 10px;
  padding-left: 30px;
  font-size: 14px;
  line-height: 1.57;

  color: $waterloo;
  overflow: hidden;
}

.image {
  object-fit: cover;
  width: 260px;
  height: 173px;
  flex-shrink: 0;
}

.footer {
  font-size: 0.8em;
  margin-top: 2em;
}
