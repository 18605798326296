@import "bvdash/styles/variables";
@import "bvdash/styles/mixins";

.btn {
  @extend %selectable;
}

.inactive {
  color: $san-juan;
	font-weight: 500;
  text-transform: capitalize;
}

.active {
  color: $dodger-blue;
  font-weight: 500;
  text-transform: capitalize;
}

.emptyLabel {
  display: inline-block;
  width: 20px;
}

.dropdown {
  position: absolute;
  top: calc(100% + 10px);
  right: 0;
  top: 0;
  max-height: 300px;
  overflow-y: auto;
  z-index: 1;
  background-color: $solid-white;
  border-radius: 5px;


  box-shadow: 0 10px 50px 0 rgba(49, 61, 90, 0.2), inset 0 -1px 0 0 #eaecee;

  // &::before {
  //  display: block;
  //  height: 0;
  //  width: 0;
  //  content: " ";
  //  margin-left: -10px;
  //
  //  position: absolute;
  //  bottom: 100%;
  //  right: 10px;
  //  z-index: 1;
  //
  //  pointer-events: none;
  //  border: 10px solid transparent;
  //  border-bottom-color: $solid-white;
  // }
}

.dropdownItem {
  @extend %selectable;
  white-space: nowrap;

  $horizontal-padding: 20px;
  $active-width: 3px;
  padding: 10px $horizontal-padding;

  & + & {
    border-top: 1px solid $drop-shadow;
  }

  &:first-child {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }

  &:last-child {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  &:hover {
    background-color: rgba(35,162,245,0.05);
  }

  &Active {
    @extend %menu-item-hover;
  }
}
