@import "bvdash/styles/variables";
@import "bvdash/styles/mixins";

.root {
  position: relative;
  display: flex;
}

.trigger {
  @extend %selectable;
  flex-grow: 1;
}

.label {
  padding-left: 10px;
}

.icon {
  position: relative;
}

.indicator {
  position: absolute;
  bottom: 50%;
  left: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  @include circle(22px);
  color: $solid-white;
  background-color: $dodger-blue;
}

.dropdown {
  top: calc(100% + 16px);
  width: 400px;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  padding: 0 20px;
  border-bottom: 1px solid $drop-shadow;
}

.empty {
  line-height: 50px;
  height: 50px;
}

.footerBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  margin: 0;
  color: $dodger-blue;
  border-top: 1px solid $drop-shadow;
}

.notification {
  @extend %selectable;

  display: flex;
  align-items: center;
  padding: 20px;

  transition: background-color 0.2s ease-out;

  &:hover {
    background-color: $alabaster-light;
    border-left: 3px solid;
    border-color: $dodger-blue;
    padding-left: 17px;
  }

  & + & {
    border-top: 1px solid $drop-shadow;
  }
}
