@import 'bvdash/styles/variables';

.root { position: relative; }
.wrapper { cursor: pointer; }

.tooltip {
  position: absolute;
  top: 0;
  left: 100%;
  min-width: 170px;
  min-height: 40px;
  padding: 14px 20px;
  border-radius: 6px;
  background-color: $solid-white;
  box-shadow: 0 6px 12px 0 rgba(49, 61, 90, .12);
  color: $san-juan;
  z-index: $zindex-tooltip;
}
