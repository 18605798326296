@import "bvdash/styles/variables";
@import "bvdash/styles/mixins";

.root {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin: 16px 0;
}

.btn {
  position: relative;
  display: flex;
  margin-left: 10px;
  //box-shadow: 0 10px 50px 0 rgba(49, 61, 90, 0.2), inset 0 -1px 0 0 #eaecee;
  border-radius: 5px;
}

.trigger {
  @extend %selectable;
  display: inline-flex;
  align-items: center;
  height: 30px;
  padding: 1px 0 1px 20px;  // padding 1px is required for border in :hover

  border-radius: 5px;
  margin-right: 4px;

  background-color: $solid-white;
  transition: background-color 0.2s ease-out;

  .open & {
    padding-bottom: 0;
    border-radius: 5px 5px 0 0;
    border-bottom: 1px solid $drop-shadow;
  }

  &:hover {
    background-color: $alabaster-light;
  }
}

.trigger,
.item {
  font-size: 12px;
  font-weight: 500;
}

.dropdown,
.trigger {

}

.value {
  padding-left: 15px;
}

.item {
  @extend %selectable;

  display: flex;
  align-items: center;
  height: 30px;
  padding: 0 15px 0 53px;
  color: $waterloo;
  transition: background-color 0.2s ease-out;

  &:hover,
  &.selected {
    color: $san-juan;
    background-color: $alabaster-light;
    border-left: 3px solid;
    border-color: $dodger-blue;
    padding-left: 50px;
  }

  &:last-child {
    border-radius: 0 0 5px 5px;
  }

  & + & {
    border-top: 1px solid $drop-shadow;
  }
}
