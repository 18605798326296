@import "bvdash/styles/variables";

.root {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
}

%cell {
  display: flex;
  flex-basis: 0;
  flex-grow: 1;
}

.years {
  @extend %cell;
  flex-direction: column;

  &:last-child {
    border-right: 1px solid $drop-shadow;
  }
}

.quarters {
  @extend %cell;
  flex-direction: row;

  border-top: 1px solid $drop-shadow;
}

.yearLabel,
.quarterLabel {
  display: flex;
  flex-basis: 0;
  flex-grow: 1;

  align-items: center;
  justify-content: center;
  border-left: 1px solid $drop-shadow;
}
