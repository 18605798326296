/*
 *  TYPOGRAPHY
 */
$primary-font: 'Sailec';

/*
 *  Layout dimensions
 */

$header-height: 66px;


/*
 *  COLORS
 */

// Primary palette
$solid-white:   #ffffff;
$alabaster-light: #fafafa;
$alabaster:     #f3f3f3;
$cadet-blue:    #b0b6c1;
$waterloo:      #7c859d;
$san-juan:      #344775;

// Secondary palette
$dodger-blue:   #23a2f5;
$wasabi:        #77c76f;
$carnation:     #ff585b;
$neon-carrot:   #fe9338;
$bright-sun:    #ffc944;
$moody-purple:  #8c7eca;
$hot-pink:      #ff71a8;

// Box-shadow
$drop-shadow:   #dcdfe2;

// z-index
$z-index-dropdown:  1000;
$z-index-fixed:     1030;
$z-index-sticky:    1030;
$zindex-modal:      1040;
$zindex-popover:    1050;
$zindex-tooltip:    1060;

:export {
  solidWhite:  $solid-white;
  dropShadow:  $drop-shadow;
  alabaster:   $alabaster;
  cadetBlue:   $cadet-blue;
  waterloo:    $waterloo;
  sanJuan:     $san-juan;

  dodgerBlue:  $dodger-blue;
  wasabi:      $wasabi;
  carnation:   $carnation;
  neonCarrot:  $neon-carrot;
  brightSun:   $bright-sun;
  moodyPurple: $moody-purple;
  hotPink:     $hot-pink;

  primaryFont: $primary-font;
}
