@import "bvdash/styles/variables";

%text-default {
  font-size: 14px;

  color: $san-juan;
}

%text-bold {
  font-size: 14px;
  font-weight: 500;
  color: $san-juan;
}

%selectable {
  cursor: pointer;
  user-select: none;
  outline: none;
}

%card-shadow {
  box-shadow: 0 2px 3px 0 rgba(49, 61, 90, 0.14);
}

%menu-item-hover {
  $hover-border: 3px;
  border-left: $hover-border solid $dodger-blue;
  padding-left: 20px - $hover-border;
  background-color: $alabaster-light;
}

@mixin circle($size) {
  width: $size;
  height: $size;
  border-radius: 50%;
}
