@import "bvdash/styles/variables";
@import "bvdash/styles/mixins";

.field {
  @extend %selectable;
  position: relative;
}

.input {
  visibility: hidden;
}

.label {
  margin-left: 14px;
  font-size: 14px;

  color: $waterloo;

  .input:checked ~ & {
    color: $san-juan;
  }
}

.checkbox {
  @extend %selectable;

  position: absolute;
  top: 1px;
  left: 0;

  display: block;
  width: 14px;
  height: 14px;
  border: solid 1px $waterloo;
  background: none;

  // check
  &::after {
    @extend %selectable;

    opacity: 0;

    position: absolute;
    left: 4px;
    top: 0;
    width: 5px;
    height: 10px;
    border: solid $dodger-blue;
    border-width: 0 2px 2px 0;

    transform: rotate(45deg);
    content: " ";
  }

  .input:checked + & {
    border: solid 1px $dodger-blue;
  }

  .input:checked + &::after {
    opacity: 1;
  }
}
