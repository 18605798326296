@import "bvdash/styles/variables";
@import "bvdash/styles/mixins";

.root {
  position: relative;
}

.trigger {
  @extend %selectable;
  color: $dodger-blue;
}

.content {
  display: flex;
  flex-direction: column;
}

.columns {
  display: flex;
  flex-direction: row;
}

.column {
  width: 285px;

  & + & {
    border-left: 1px solid $drop-shadow;
  }
}

.title {
  @extend .column;
  @extend %text-bold;
  margin: 10px 0;
  font-size: 24px;
	color: $san-juan;
  text-align: center;
}

.options {
  @extend .columns;
  border-top: 1px solid $drop-shadow;
}

.checkbox {
  padding-left: 21px;
  line-height: 34px;

  &:hover {
    padding-left: 15px;
    border-left: 6px solid $dodger-blue;
    background-color: $alabaster-light;
  }
}

.buttons {
  margin: 15px 5px 15px calc(50% - 10px);
  display: flex;
  justify-content: space-between;

  & > * {
    margin: 0 10px;
  }
}
