@import "bvdash/styles/variables";

.section {
  padding: 12px 24px 24px;
  display: flex;
  flex-direction: column;
  flex: 1 1 0;

}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.body {
  display: flex;
  flex-grow: 1;
  flex-direction: column;

  &.start { justify-content: flex-start; }
  &.end { justify-content: flex-end;; }
  &.center { justify-content: center; }
}

.number {
  font-size: 40px;
  color: $san-juan;
}

.description {
  margin-top: 10px;
  font-size: 14px;
  font-weight: 500;
  color: $dodger-blue;
}
