@import "bvdash/styles/variables";

.searchbox {
  display: flex;
  flex-grow: 1;
  align-items: center;
  color: $waterloo;
  overflow: hidden;
}

.input {
  margin-left: 15px;
  flex-grow: 1;
  border: none;
  color: $waterloo;
  font-size: 12px;
  width: 100%;
  outline: none;
}
