/* Position and sizing of burger button */
.bm-burger-button {
    width: 36px;
    height: 30px;
  }

  /* Color/shape of burger icon bars */
  .bm-burger-bars {
    background: #344775;
  }

  /* Color/shape of burger icon bars on hover*/
  .bm-burger-bars-hover {
    background: #23a2f5;
  }

/* This positions the cross centrally in the button */
/* .bm-cross-button > span {
    position: absolute;
    top: 2px !important;
    right: 200px !important;
} */

/* This targets the actual elements that make up the cross */
/* .bm-cross {
    width: 5px !important;
    height: 20px !important;
} */

  /* Position and sizing of clickable cross button */
  .bm-cross-button {
    position: relative;
    top: 200px;
    height: 100px;
    width: 100px;
    /* right: 260px !important; */
  }

  /* Color/shape of close button cross */
  .bm-cross {
    background: #bdc3c7;


  }

  /*
  Sidebar wrapper styles
  Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
  */
  .bm-menu-wrap {
    position: absolute;
    height: 0px;
    /* right: 1050px !important; */
    left: 0px;
    top: 0px;
    width: 260px !important;



  }

  /* General sidebar styles */
  .bm-menu {
    position:absolute;
    background: #FFFFFF;
    padding: 2em 1.5em 0;
    font-size: 1.15em;
    width: 260px;
    right: 0px;
  }

  /* Morph shape necessary with bubble or elastic */
  .bm-morph-shape {
    fill: #373a47;
    height: 200px;
  }

  /* Wrapper for item list */
  .bm-item-list {
    color: #b8b7ad;
    padding: 0.8em;
  }

  /* Individual item */
  .bm-item {
    display: inline-block;
    padding: 15px;
  }

  /* Styling of overlay */
  .bm-overlay {
    display: none;
  }

  @media screen and (min-width:1271px){
    .mb-overlay, .bm-item, .bm-item-list, .bm-morph-shape, .bm-menu, .bm-menu-wrap, .bm-cross, .bm-cross-button, .bm-burger-bars-hover, .bm-burger-bars, .bm-burger-button {
        display: none;
    }
}

/* @media screen and (max-width:900px){
    .bm-burger-button {
        position: absolute;
        width: 36px;
        height: 30px;
        left: 90%;
        top: 20px;
    }
}

@media screen and (max-width:375px){
    .bm-burger-button {
        position: absolute;
        width: 36px;
        height: 30px;
        left: 85%;
        top: 20px;
    }
} */
