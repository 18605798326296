@import "bvdash/styles/variables";
@import "bvdash/styles/mixins";

.box {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  min-width: 1em;
  padding: 5px;
}

.clickable {
  @extend %selectable;
  outline: none;
}

.theme {
  &default {
    background-color: #CEE8F9; // rgba($dodger-blue, 0.25);
  }
  &success {
    background-color: rgba($wasabi, 0.25);
  }
  &danger {
    background-color: rgba($carnation, 0.25);
  }
}
