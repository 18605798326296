@import "bvdash/styles/variables";

.root {
  width: 480px;
  margin-left: -10px;
  margin-right: -30px;
}

.chart {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-left: 1px solid $drop-shadow;
  overflow: hidden;
}

.quarters {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
}

.quarterLabel {
  flex-basis: 0;
  flex-grow: 1;

  &:nth-child(2n) {
    background-color: #fcfcfc;
    border-right: 1px solid $drop-shadow;
    border-left: 1px solid $drop-shadow;
  }
}

.today {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 1px;
  border-left: 2px solid transparentize($carnation, 0.8);
}

.current {
  position: absolute;
  top: 26%;
  min-width: 2px;
  height: 8px;
  background-color: $dodger-blue;
  border-radius: 2px;
}

.baseline {
  position: absolute;
  top: 63%;
  min-width: 2px;
  height: 4px;
  background-color: $bright-sun;
  border-radius: 2px;
}

.milestoneCurrent {
  position: absolute;
  top: 24%;
  height: 10px;
  width: 10px;
  transform: rotate(45deg) translateX(-5px);
  background-color: $san-juan;
  border-radius: 2px;
}

.milestoneBaseline {
  position: absolute;
  top: 63%;
  height: 6px;
  width: 6px;
  transform: rotate(45deg) translateX(-7px);
  background-color: $bright-sun;
  border-radius: 2px;
}
