@import "bvdash/styles/variables";
@import "bvdash/styles/mixins";

.online {
  @include circle(8px);

  display: inline-block;
  background-color: $wasabi;
  position: absolute;
  top: 2px;
  left: 9px;
}
