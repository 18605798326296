@import 'bvdash/styles/variables';

.card {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: calc(50% - 10px);
  min-width: 296px;
  margin-bottom: 20px;
  position: relative;
  border-radius: 5px;
  background-color: $solid-white;
  box-shadow: 0 2px 3px 0 rgba(49, 61, 90, 0.14), inset 0 -1px 0 0 $drop-shadow;

  @media (max-width: 656px) {
    width: 100%;
  }
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
  margin: 0 8px;
  // Required for MS Edge, otherwise header is collapsed below fixed height
  flex-shrink: 0;
  padding: 8px;
  box-shadow: inset 0 -1px 0 0 $drop-shadow;
  max-width: 100%;
}

.body {
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  justify-content: center;
  min-height: 0;
  width: 100%;
}

.buttonDetails {
  right: 20px;
  bottom: 20px;
}

.footer {
  display: flex;
  flex-direction: row-reverse;
  margin-right: 24px;
  margin-bottom: 24px;
}
