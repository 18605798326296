@import 'bvdash/styles/variables';

%cell {
  position: relative;
  padding: 5px 10px 5px 10px;
  vertical-align: middle;

  &:first-child {
    padding-left: 30px;
  }

  &:last-child {
    padding-right: 30px;
  }
}

.cell {
  @extend %cell;
  font-size: 12px;
}

.header {
  @extend %cell;
  padding-top: 7px;
  padding-bottom: 7px;
  color: $waterloo;

  text-transform: uppercase;
  font-size: 10px;
  font-weight: 500;

  white-space: nowrap;
  overflow: hidden;
}

.footer {
  @extend %cell;
  color: $san-juan;

  font-size: 14px;
  font-weight: 500;

}

.stretch {
  width: 50%;
}

.shrink {
  width: 1px;
  white-space: nowrap;
}

.center {
  text-align: center;
}

.right {
  text-align: right;
}
