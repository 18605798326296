@import "bvdash/styles/variables";
@import "bvdash/styles/mixins";

.updatesWrapper {
  position: relative;
  display: flex;
  flex-direction: row;
  padding-bottom: 300px;
}

.updates {
  // position is required so element.offsetTop returns
  // relative position of update
  position: relative;

  flex-grow: 1;
  flex-basis: 55%;
  margin-right: 20px;
}

.update {
  @extend %selectable;
  @extend %card-shadow;

  margin-bottom: 20px;
  background-color: rgba(49, 61, 90, 0.03);
  border-radius: 5px;

  outline: none;

  &:hover {
    background-color: rgba(49, 61, 90, 0.06);
  }
}

.selected {
  background-color: $solid-white;
  border-left: 5px solid $dodger-blue;

  &:hover {
    background-color: $solid-white;
  }

  .header,
  .description {
    padding-left: 45px;
  }
}

.header {
  @extend %text-bold;
  position: relative;
  padding: 0 130px 0 50px;

  display: flex;
  flex-direction: row;
  align-items: center;

  line-height: 42px;
  color: $waterloo;
  border-bottom: 1px solid $drop-shadow;
}

.headerAuthor {
  flex-grow: 1;
}

.headerEdit {
  position: absolute;
  right: 25px;
  top: 4px;
}

.description {
  padding: 25px 50px;
  line-height: 1.5;
  white-space: pre-line;
}

.editing {
  padding-left: 45px;
  padding-right: 20px;
  padding-bottom: 10px;
}

.input {
  padding: 10px 0;
  width: 100%;

  border: none;
  resize: none;
  outline: none;

  font-size: 14px;
  letter-spacing: 1.1px;
  font-weight: 500;
}

.upload {
  @extend %card-shadow;
  background-color: $solid-white;
}

.placeholder {
  padding: 26px;
}

.uploadWrapper {
  position: relative;
  flex-basis: 400px;
  flex-grow: 0;
  flex-shrink: 0;
}
