@import "bvdash/styles/variables";

$arrow-height: 10px;

.root {
  position: absolute;
  top: calc(100% + #{$arrow-height});
  z-index: 2;
  background-color: $solid-white;
  border-radius: 5px;
  text-transform: capitalize;

  box-shadow: 0 5px 10px 0 rgba(49, 61, 90, 0.2), inset 0 -1px 0 0 #eaecee;

   z-index: 100;

  &::before {
    display: block;
    height: 0;
    width: 0;
    content: " ";
    margin-left: -10px;

    position: absolute;
    bottom: 100%;
    z-index: 1;

    pointer-events: none;
    border: $arrow-height solid transparent;
    border-bottom-color: $solid-white;
  }

  &.arrowLess{
    top: 100%;
    border-radius: 0 0 5px 5px;

    &::before {
      display: none;
    }
  }
}

.left {
  left: 0;

  &::before {
    left: 20px;
  }
}

.right {
  right: 0;

  &::before {
    right: 10px;
  }
}

.center {
  left: 50%;
  transform: translateX(-50%);

  &::before {
    left: 50%;
  }
}

.tableConfig {
  right: -63px;
  top: calc(100% + 15px);

  &::before {
    right: 70px;
  }
}

