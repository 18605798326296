@import "bvdash/styles/variables";

%body {
  width: 100%;
  display: flex;
}

.row {
  display: flex;
  flex-grow: 1;
}

.four {
  @extend %body;
  flex-wrap: wrap;

  .row {
    width: 50%;
    min-width: 296px;

    &:nth-child(n + 3) { border-top: 1px solid $drop-shadow; }

    &:nth-child(2n) { border-left: 1px solid $drop-shadow; }

    @media (max-width: 1267px) {
      &:nth-child(2n) {
          border-top: 1px solid $drop-shadow;
          border-left: none;
      }

    }

  }
}

.two {
  @extend %body;
  padding-top: 60px;
  padding-bottom: 60px;
  display: flex;
  flex-grow: 1;
  text-align: center;

  .row:first-child {
    border-right: 1px solid $drop-shadow;
  }
}
