@import "bvdash/styles/mixins";

.root {
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.iconWrapper {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  padding: 24px;
  height: 250px;
  flex: 1;
}

.icon {
  width: 134px;
  height: 134px;
}

.list {
  flex-grow: 1;
}

.legend {
    display: flex;
    flex-direction: column;
    padding: 32px;
    margin: auto;
    max-width: 414px;
}

.legendValue {
  @extend %text-bold;
  float: right;
}

.legendItem {
    align-items: center;
    display: flex;
    line-height: 30px;
    justify-content: space-between;
}

.legendHeader {
    font-size: 24px;
  font-weight: 500;
  margin-bottom: 16px;
  line-height: 1.25em;
}
