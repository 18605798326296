@import "bvdash/styles/variables";
@import "bvdash/styles/mixins";

.table {
  width: 100%;

  border-top: 1px solid #dcdfe2;
  border-radius: 5px;
  background-color: white;
  box-shadow: 0 2px 3px 0 rgba(49, 61, 90, 0.14);
}

.group {
  padding-left: 30px;

  color: rgba(124, 133, 157, 0.9);
  background-color: #fcfcfc;

  font-size: 10px;
  line-height: 25px;
  text-transform: uppercase;
}

.groupRow {
  border-top: 1px solid $drop-shadow;

  &:first-child {
    border-top: none;
  }
}

.expandCell {
  vertical-align: middle;
  text-align: center;
}

.expand {
  @extend %selectable;
  color: $waterloo;
}
