@import "bvdash/styles/mixins";

.root {
  position: relative;
  height: 2em;
  width: 40px;
}

.trigger {
  @extend %selectable;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
