@import "bvdash/styles/variables";
@import "bvdash/styles/mixins";

.dropzone {
  border: none;
  width: 100%;
}

.files {
  padding: 15px;
  //margin: 0 -5px;

  max-height: 30%;
  overflow-y: auto;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.file {
  padding: 2px 16px;
  margin: 2px 5px;

  border-radius: 2px;
	background-color: $dodger-blue;
  color: $solid-white;

  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
}

.filename {
  display: inline-block;
  vertical-align: middle;
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.remove {
  @extend %selectable;
  vertical-align: middle;
  margin-left: 8px;
}
