@import "bvdash/styles/variables";
@import "bvdash/styles/mixins";

.btn {
  margin-right: 0;
}

.clear:hover {
  text-decoration: underline;
}

.conversation {
  display: flex;
  align-items: center;
  width: 100%;
  text-decoration: none;
}

.conversationContent {
  flex-grow: 1;
  margin-left: 15px;
}

.header {
  display: flex;
  justify-content: space-between;
  padding-bottom: 5px;
}

.date {
  color: $waterloo;
}

.conversationDescription {
  margin-top: 5px;

  color: $waterloo;
  font-size: 13px;
	line-height: 1.38;
}
